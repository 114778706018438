@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Black.woff2') format('woff2'),
        url('HelveticaNeue-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BlackCondObl.woff2') format('woff2'),
        url('HelveticaNeue-BlackCondObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BlackCond.woff2') format('woff2'),
        url('HelveticaNeue-BlackCond.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BlackExt.woff2') format('woff2'),
        url('HelveticaNeue-BlackExt.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BlackExtObl.woff2') format('woff2'),
        url('HelveticaNeue-BlackExtObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Condensed.woff2') format('woff2'),
        url('HelveticaNeue-Condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BoldExt.woff2') format('woff2'),
        url('HelveticaNeue-BoldExt.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Bold.woff2') format('woff2'),
        url('HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCondensedBlack';
    src: url('HelveticaNeue-CondensedBlack.woff2') format('woff2'),
        url('HelveticaNeue-CondensedBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BoldCondObl.woff2') format('woff2'),
        url('HelveticaNeue-BoldCondObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BoldExtObl.woff2') format('woff2'),
        url('HelveticaNeue-BoldExtObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-ExtendedObl.woff2') format('woff2'),
        url('HelveticaNeue-ExtendedObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCondensedBold';
    src: url('HelveticaNeue-CondensedBold.woff2') format('woff2'),
        url('HelveticaNeue-CondensedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Extended.woff2') format('woff2'),
        url('HelveticaNeue-Extended.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyCond.woff2') format('woff2'),
        url('HelveticaNeue-HeavyCond.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-ExtBlackCond.woff2') format('woff2'),
        url('HelveticaNeue-ExtBlackCond.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-CondensedObl.woff2') format('woff2'),
        url('HelveticaNeue-CondensedObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyCondObl.woff2') format('woff2'),
        url('HelveticaNeue-HeavyCondObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Heavy.woff2') format('woff2'),
        url('HelveticaNeue-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-ExtBlackCondObl.woff2') format('woff2'),
        url('HelveticaNeue-ExtBlackCondObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyExt.woff2') format('woff2'),
        url('HelveticaNeue-HeavyExt.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-LightExt.woff2') format('woff2'),
        url('HelveticaNeue-LightExt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-LightCondObl.woff2') format('woff2'),
        url('HelveticaNeue-LightCondObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyExtObl.woff2') format('woff2'),
        url('HelveticaNeue-HeavyExtObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyItalic.woff2') format('woff2'),
        url('HelveticaNeue-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-LightExtObl.woff2') format('woff2'),
        url('HelveticaNeue-LightExtObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

