
body {
  --icon-fill: white;
  --icon-fill-hover: $primary;
}

.theme-toggle {
  --size: 2rem;

  background: none;
  border: none;
  padding: 0;

  inline-size: var(--size);
  block-size: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;

  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  outline-offset: 5px;

  & > svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round;
  }

  @media (hover: none) {
    --size: 48px;
  }
}

.sun-and-moon {
  & > :is(.moon, .sun, .sun-beams) {
    transform-origin: center center;
  }

  & > :is(.moon, .sun) {
    fill: var(--icon-fill);

    @at-root .theme-toggle:is(:hover, :focus-visible) & {
      opacity: .7;
    }
  }

  & > .sun-beams {
    stroke: var(--icon-fill);
    stroke-width: 2px;

    @at-root .theme-toggle:is(:hover, :focus-visible) & {
      // stroke: var(--icon-fill-hover);
    }
  }
}

.sun-and-moon {
  @at-root [data-theme="dark"] & {
    & > .sun {
      transform: scale(1.75);
    }

    & > .sun-beams {
      opacity: 0;
    }

    & > .moon > circle {
      transform: translateX(-7px);

      @supports (cx: 1) {
        transform: translateX(0);
        cx: 17;
      }
    }
  }
}

@import "https://unpkg.com/open-props/easings.min.css";
@custom-media --motionOK (prefers-reduced-motion: no-preference);

/* usage example */
@media (--motionOK) {
  .sun {
    transition: transform 0.5s var(--ease-elastic-3);
  }
}

.sun-and-moon {
  @media (--motionOK) {
    & > .sun {
      transition: transform 0.5s var(--ease-elastic-3);
    }

    & > .sun-beams {
      transition: transform 0.5s var(--ease-elastic-4), opacity 0.5s var(--ease-3);
    }

    @at-root [data-theme="dark"] & {
      & > .sun {
        transform: scale(1.75);
        transition-timing-function: var(--ease-3);
        transition-duration: 0.25s;
      }

      & > .sun-beams {
        transform: rotateZ(-25deg);
        transition-duration: 0.15s;
      }
    }
  }
}

.sun-and-moon {
  @media (--motionOK) {
    & .moon > circle {
      transform: translateX(-7px);
      transition: transform 0.25s var(--ease-out-5);

      @supports (cx: 1) {
        transform: translateX(0);
        cx: 17;
        transition: cx 0.25s var(--ease-out-5);
      }
    }

    @at-root [data-theme="dark"] & {
      & > .moon > circle {
        transition-delay: 0.25s;
        transition-duration: 0.5s;
      }
    }
  }
}

// body {
//     --icon-fill: white;
//     --icon-fill-hover: $primary;
// }

// .theme-toggle {
//     --size: 2rem;

//     background: none;
//     border: none;
//     padding: 0;

//     inline-size: var(--size);
//     block-size: var(--size);
//     aspect-ratio: 1;
//     border-radius: 50%;

//     cursor: pointer;
//     touch-action: manipulation;
//     -webkit-tap-highlight-color: transparent;
//     outline-offset: 5px;

//     &>svg {
//         inline-size: 100%;
//         block-size: 100%;
//         stroke-linecap: round;
//     }

//     @media (hover: none) {
//         --size: 48px;
//     }
// }

// .sun-and-moon {
//     &> :is(.moon, .sun, .sun-beams) {
//         transform-origin: center center;
//     }

//     &> :is(.moon, .sun) {
//         fill: var(--icon-fill);

//         @nest .theme-toggle:is(:hover, :focus-visible)>& {
//             fill: var(--icon-fill-hover);
//         }
//     }

//     &>.sun-beams {
//         stroke: var(--icon-fill);
//         stroke-width: 2px;

//         @nest .theme-toggle:is(:hover, :focus-visible) & {
//             stroke: var(--icon-fill-hover);
//         }
//     }
// }

// .sun-and-moon {
//     @nest [data-theme="dark"] & {
//         &>.sun {
//             transform: scale(1.75);
//         }

//         &>.sun-beams {
//             opacity: 0;
//         }

//         &>.moon>circle {
//             transform: translateX(-7px);

//             @supports (cx: 1) {
//                 transform: translateX(0);
//                 cx: 17;
//             }
//         }
//     }
// }

// @import "https://unpkg.com/open-props/easings.min.css";
// @custom-media --motionOK (prefers-reduced-motion: no-preference);

// /* usage example */
// @media (--motionOK) {
//   .sun {
//     transition: transform .5s var(--ease-elastic-3);
//   }
// }

// .sun-and-moon {
//     @media (--motionOK) {
//         &>.sun {
//             transition: transform .5s var(--ease-elastic-3);
//         }

//         &>.sun-beams {
//             transition:
//                 transform .5s var(--ease-elastic-4),
//                 opacity .5s var(--ease-3);
//         }

//         @nest [data-theme="dark"] & {
//             &>.sun {
//                 transform: scale(1.75);
//                 transition-timing-function: var(--ease-3);
//                 transition-duration: .25s;
//             }

//             &>.sun-beams {
//                 transform: rotateZ(-25deg);
//                 transition-duration: .15s;
//             }
//         }
//     }
// }

// ​​.sun-and-moon {
//     @media (--motionOK) {
//         & .moon>circle {
//             transform: translateX(-7px);
//             transition: transform .25s var(--ease-out-5);

//             @supports (cx: 1) {
//                 transform: translateX(0);
//                 cx: 17;
//                 transition: cx .25s var(--ease-out-5);
//             }
//         }

//         @nest [data-theme="dark"] & {
//             &>.moon>circle {
//                 transition-delay: .25s;
//                 transition-duration: .5s;
//             }
//         }
//     }
// }