@import '../fonts/Helvetica/stylesheet.css';
@import '../fonts/HelveticaNeue/stylesheet.css';
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

// @import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";
@import "../../node_modules/photoswipe/dist/photoswipe.css";
@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "./site/theme_switcher.scss";

*[data-controller="hover-effect"] {
   transform: rotate3d(0);
}

.swiper-button-disabled {
   border-color: black;
   color: black;
   opacity: .2;
}

.ctn-float-btn-whats {
   display: block!important;
   position: fixed;
   bottom: -100px;
   z-index: 11;
   right: 30px;
   width: 50px;
   height: 50px;
   transition: .3s;

   &.float-btn-effect {
       bottom: 100px;
       transition: .3s;
   }

   &:before {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       width: 50px;
       height: 50px;
       background: transparent;
       border: 4px solid rgba( #2de779, .85);
       z-index: 1;
       content: ' '; 
       border-radius: 50%;
       animation: pulseBorder1 1.25s infinite;
   }

   &:after {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       width: 50px;
       height: 50px;
       background: transparent;
       border: 4px solid rgba( #2de779, .75);
       z-index: 2;
       content: ' '; 
       border-radius: 50%;
       animation: pulseBorder2 1.25s infinite;
       animation-delay: -300ms;
   }
}

@keyframes pulseBorder1 {
   0% {  }
   100% { width: 90px; height: 90px; opacity: 0; }
}
@keyframes pulseBorder2 {
   0% {  }
   100% { width: 100px; height: 100px; opacity: 0; }
}

.float-btn-whats {
   position: relative;
   z-index: 3;
   transition: .3s;
   background: #2de779;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   width: 100%;
   height: 100%;
   text-align: center;
   line-height: 45px;
   z-index: 11;
   color: #fff !important;
   font-size: 26px;
   box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.15);
   transition: .3s;
   &:hover {
       background: darken(#2de779, 30%);
       transition: .3s;
   }
}